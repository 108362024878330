import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import React, { useEffect, useState } from "react";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { AgGridReact } from "ag-grid-react";

import { RuleBuilder } from "./RuleBuilder";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HelpIcon from "@mui/icons-material/Help";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import classes from "./RuleViewer.module.css";
import { useSnackbar } from "notistack";
import { Rule, RuleType } from "../../api/rules";
export const RuleActionArea = (
  props: Readonly<{
    onEditButtonClicked(): void;
    onAddButtonClicked(): void;
    selectedRow: number;
    testButtonVisible: boolean;
    onTestButtonClicked(): void;
    onCopyButtonClicked(): void;
  }>
) => {
  const {
    onEditButtonClicked,
    onAddButtonClicked,
    selectedRow,
    onTestButtonClicked,
    testButtonVisible,
    onCopyButtonClicked,
  } = props;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => onAddButtonClicked()}
      >
        Add
      </Button>

      <Button
        variant="contained"
        color="primary"
        startIcon={<EditOutlinedIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => onEditButtonClicked()}
        disabled={!selectedRow && selectedRow !== 0}
      >
        Edit
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ContentCopyIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => onCopyButtonClicked()}
        disabled={!selectedRow && selectedRow !== 0}
      >
        Copy
      </Button>
      {testButtonVisible && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<HelpIcon />}
          size="small"
          className={classes.ruleButtons}
          onClick={() => onTestButtonClicked()}
          disabled={!selectedRow && selectedRow !== 0}
        >
          Test
        </Button>
      )}
    </>
  );
};

export const NewRuleModal = (props: {
  isOpen: boolean;
  selectedRuleId: number;
  onClose: () => void;
  onRuleSaved: (_ruleDetails: any) => void;
  ruleGroupId: number;
  ruleType: RuleType;
  selectedRule: Rule;
}) => {
  return (
    <Modal open={props.isOpen} className={classes.modal}>
      <Container maxWidth="lg">
        <Card style={{ padding: "16px" }}>
          <CardHeader
            title={!props.selectedRuleId ? "New Rule" : "Edit Rule"}
            titleTypographyProps={{ variant: "h3" }}
            action={
              <IconButton onClick={props.onClose}>
                <CloseOutlinedIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent
            style={{
              maxHeight: (window.outerHeight * 0.7).toString() + "px",
              overflow: "overlay",
            }}
          >
            {props.selectedRuleId !== undefined && (
              <RuleBuilder
                ruleId={props.selectedRuleId}
                onRuleSaved={props.onRuleSaved}
                ruleGroupId={props.ruleGroupId}
                ruleType={props.ruleType}
              />
            )}
            {props.selectedRuleId === undefined && props.selectedRule && (
              <RuleBuilder
                rule={props.selectedRule}
                onRuleSaved={props.onRuleSaved}
                ruleGroupId={props.ruleGroupId}
                ruleType={props.ruleType}
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};
